import React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import moment from 'moment'
import Link from 'next/link'
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';



export default function EventOne(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData ? JSON.parse(themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  const eventCardSix = {
    p:1, 
    border:`1px solid ${theme.palette.primary.borderColor}`,
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    background: theme.palette.background.default,
    borderRadius:4,
    mb:2,
    transition: `0.5s`,
    border:`1px solid ${theme.palette.background.borderColor}`,
    boxShadow: theme.shadows[0],
    "&:hover":{
      transition: `0.5s`,
      boxShadow: theme.shadows[3],
      background: theme.palette.background.alternate
    },
    '& h3':{
      fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:{md:24, sm:22, xs:20},
      fontWeight:500,
      color: `${themeCss && themeCss.theme_main_color} !important`,
      mb:{md:2.5,sm:1.5, xs:1},
      textTransform:'capitalize',
    },
    '& h5':{
      fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:`24px !important`,
      fontWeight:700,
      color: `${themeCss && themeCss.theme_main_color} !important`,
      mb:1,
    },
    '& h6':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:`16px !important`,
      fontWeight:400,
      color: theme.palette.primary.location,
    },
    h4:{
      pb:0
    }
}

const ButtonDateSix = {
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    '& h5':{
      fontSize:{md:`24px !important`,sm:`16px !important`,xs:`16px !important`},
      fontWeight:900,
      color: theme.palette.primary.contrastText,
      mb:`0px !important`,
    },
    '& h6':{
      fontSize:{md:`18px !important`,sm:`12px !important`,xs:`12px !important`},
      fontWeight:400,
      color: theme.palette.primary.contrastText,
      mb:0,
    },
  }

  const iconStyle = {
    color: theme.palette.primary.location,
    fontSize: 18,
    mr:1,
  }
  

  const EVENT_TITLE_MAX_LENGTH = 40;

  return (
    <Link href={'/event/'+props.data.slug+'/'+props.data.id}>
      <Card sx={{...eventCardSix, display:'flex'}}>
          <Grid container spacing="5">
            {props.data.only_today !='' && props.data.only_today != '0000-00-00' ? (
              <Grid item xs="2" align={'left'}>
                <CardContent sx={{ textAlign:'center', ...ButtonDateSix, px:{md:'inherit', sm:0, xs:0} }}>
                    <Typography variant="h6">{moment(props.data.only_today).format("ddd")}</Typography>
                    <Typography variant="h5">{moment(props.data.only_today).format("DD")}</Typography>
                    <Typography variant="h6">{moment(props.data.only_today).format("MMM")}</Typography>
                </CardContent>
              </Grid>
            ):(
              <Grid item xs="3" align={'left'}>
                <CardContent sx={{ textAlign:'center', ...ButtonDateSix, px:{md:'inherit', sm:0, xs:0} }}>
                    <Typography variant="h6">{moment(props.data.from_date).format("ddd")} - {moment(props.data.to_date).format("ddd")}</Typography>
                    <Typography variant="h5">{moment(props.data.from_date).format("DD")} - {moment(props.data.to_date).format("DD")}</Typography>
                    <Typography variant="h6">{moment(props.data.from_date).format("MMM")} - {moment(props.data.to_date).format("MMM")}</Typography>
                </CardContent>
              </Grid>
            )}
              <Grid item xs={props.data.only_today !='' && props.data.only_today != '0000-00-00' ? 7:6} alignSelf={'start'} sx={{mt:2.5}}>
                  <Typography variant="h3">
                      {(props.data.title.length >= EVENT_TITLE_MAX_LENGTH
                          ? (props.data.title.toLowerCase()).substring(0, EVENT_TITLE_MAX_LENGTH) +
                          "..."
                          : props.data.title.toLowerCase()
                      ).toLowerCase()}
                  </Typography>
                  <Typography variant="h6"><CalendarMonthIcon sx={iconStyle} />&nbsp;{props.data.start_time}&nbsp;onwards</Typography>
              </Grid>
              <Grid item xs="3" alignSelf={'center'} align={'right'}>
                  <CardMedia
                      component="img"
                      sx={{ width: {md:100, sm:70, xs:70}, height:{md:100, sm:70, xs:70}, borderRadius:2, border:`1px solid #5D5D5D`}}
                      image={props.data.img_path}
                      alt={props.data.title}
                  />
              </Grid>
          </Grid>
      </Card>
    </Link>
  );
}