import React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Grid, Stack } from '@mui/material';
import ButtonTypeFour from '../../components/buttons/TypeFour'
import moment from 'moment'
import Link from 'next/link'
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


export default function EventTwo(props) {

  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData ? JSON.parse(themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  const eventCardFour = {
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    background:`transparent`,
    boxShadow:0,
    borderRadius:3,
    transition:`0.5s`,
    border:`1px solid ${theme.palette.background.borderColor}`,
    mb:0,
    '& h3':{
      fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:24,
      fontWeight:500,
      color: `${themeCss && themeCss.theme_main_color} !important`,
      mb:2,
      textTransform:'capitalize',
    },
    '& h5':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:12,
      fontWeight:400,
      color: theme.palette.primary.contrastText,
    },
    '& h6':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:12,
      fontWeight:400,
      color: theme.palette.primary.contrastText,
    },
    '&: hover':{
      transition:`0.5s`,
      background:`${theme.palette.background.alternate} !important`,
      boxShadow: theme.shadows[4]
    },
    h4:{
      pb:0
    }
}

const ButtonDateFour = {    
    p:0.5,
    textAlign:'center',
    '& h5':{
       fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
        fontSize:20,
        fontWeight:700,
        mb:0,
        textAlign:'center',
        display:'block',
        color: `${themeCss && themeCss.theme_main_color} !important`,
    },
    '& h6':{
        fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
        fontSize:12,
        fontWeight:400,
        mb:0,
        textAlign:'center',
        display:'block',
        color: theme.palette.primary.location,
    },
}


const iconStyle = {
  color: theme.palette.primary.location,
  fontSize: 18,
  mr:1,
}

  const EVENT_TITLE_MAX_LENGTH = 20;
  
  return (
    <Link href={`/event/`+props.data.slug+`/`+props.data.id}>
      <Grid container spacing="0">
        {props.data.only_today != '' && props.data.only_today != '0000-00-00' ? (
          <Grid item xs={3}>
            <Typography component="div" sx={{...ButtonDateFour}}>
                <Typography variant="h6" sx={{display:'flex'}}>{moment(props.data.only_today).format('MMM')}</Typography>
                <Typography variant="h5" sx={{display:'flex'}}>{moment(props.data.only_today).format('DD')}</Typography>
                <hr style={{margin:'2px auto', border:'1px solid #8F8F8F', width:'80%', textAlign:'center'}}/>
                <Typography variant="h6" sx={{display:'flex'}}>{props.data.start_time}<br/>onwards</Typography>
            </Typography>
        </Grid>
        ):(
          <Grid item xs={3}>
            <Typography component="div" sx={{...ButtonDateFour}}>
                <Typography variant="h6" sx={{display:'flex'}}>{moment(props.data.from_date).format('MMM')} - {moment(props.data.to_date).format('MMM')}</Typography>
                <Typography variant="h5" sx={{display:'flex'}}>{moment(props.data.from_date).format('DD')} - {moment(props.data.to_date).format('DD')}</Typography>
                <hr style={{margin:'2px auto', border:'1px solid #8F8F8F', width:'80%', textAlign:'center'}}/>
                <Typography variant="h6" sx={{display:'flex'}}>{props.data.start_time}<br/>onwards</Typography>
            </Typography>
          </Grid>
        )}
          
          <Grid item xs={9}>
              <Card sx={{...eventCardFour, maxWidth: 375}} >
                  <CardMedia
                      component="img"
                      height="249"
                      width="249"
                      image={props.data.img_path}
                      alt={props.data.title}
                  />            
                  <CardContent className={'cardContent'} sx={{borderRadius:5, px:1, boxShadow:0, minHeight:100}}>
                  <Typography variant="h3">
                      {(props.data.title.length >= EVENT_TITLE_MAX_LENGTH
                          ? (props.data.title.toLowerCase()).substring(0, EVENT_TITLE_MAX_LENGTH) +
                          "..."
                          : props.data.title.toLowerCase()
                      ).toLowerCase()}
                      </Typography>
                      <Stack alignItems="center" justifyContent={'space-between'} flexDirection={'row'}>
                        <Typography variant="h5" 
                          sx={{
                            background:theme.palette.background.alternate, 
                            py:0.5, 
                            borderRadius:5, 
                            textAlign:'left', 
                            px:1,
                            '& :hover':{
                              background:theme.palette.background.default, 
                            }
                          }}>{props.data.etype}</Typography>
                        <Typography component="div" sx={{display:'flex', justifyContent:'flex-end'}}>
                        {props.disableButton ? (''):(
                            <ButtonTypeFour text="Buy" link={'/event/'+props.data.slug+'/'+props.data.id} />
                        )}
                        </Typography>
                      </Stack>
                  </CardContent>
              </Card>
          </Grid>
      </Grid>
    </Link>
  );
}