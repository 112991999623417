import React, {useState, useEffect} from 'react';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import moment from 'moment'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import Image from 'next/image'
import Link from 'next/link'
import EastIcon from '@mui/icons-material/East';
import axios from 'axios'
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';



export default function EventTwo(props) {

  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData ? JSON.parse(themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  const [tickets, setTickets] = useState([]);  
  const [startingPrice, setStartingPrice] = useState(0)
  const [ticketStatus, setTicketStatus] = useState(0)
  const [reserveStatus, setReserveStatus] = useState(0)
  
  useEffect(() => {
    const logic = async () => {
      let res2 = await axios.get(
        `/api/events/tickets?slug=${props.data.slug}&id=${props.data.id}`,
        {
          headers: {
            "x-auth-token": process.env.JWT_TOKEN,
          },
        }
      );  
      setTickets(res2.data ? res2.data.result : []) 
      setStartingPrice(tickets.length > 0 ? tickets[0].price : 0)
      let reserveStatus = tickets.some((ticket) => ticket.price > 0 && ticket.status == 1);
      setReserveStatus(reserveStatus)
      let ticketStatus = tickets.some((ticket) => ticket.status == 1);  
      setTicketStatus(ticketStatus)
      // alert(ticketStatus)
    }
    logic();
  },[ticketStatus]); 

  const eventCardTwo = {
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    background:'transparent',
    borderRadius:0,
    mb:2,
    boxShadow:theme.shadows[0],
    mb:1,
    '& h3':{
      fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:24,
      fontWeight:500,
      color: `${themeCss && themeCss.theme_main_color} !important`,
      mb:1,
      textTransform:'capitalize',
    },
    '& h5':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:14,
      fontWeight:400,
      color: theme.palette.primary.location,
      mb:2,
    },
    '& h6':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:12,
      fontWeight:400,
      color: theme.palette.primary.contrastText,
      display:'flex',
      alignItems:'center'
    },
    h4:{
      pb:0
    }
}

const ButtonStyleThree = {
    '& h4':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:22,
      fontWeight:500,
      color: theme.palette.background.default,
      mb:0,
      pb:0,
      textTransform:'capitalize',
    },
    '& h5':{
        fontSize:20,
        fontWeight:700,
        mb:0,
        color: theme.palette.background.default,
    },
    '& h6':{
        mt:0.2,
        fontSize:14,
        fontWeight:500,
        mb:0,
        color: theme.palette.background.default,
    },
    '& a':{
      fontFamily: themeCss ? themeCss.theme_body_font.replace("+"," ") : '',
      borderRadius:0,
      background:themeCss ? themeCss.theme_main_color: ``,
      color: theme.palette.background.default,
      textDecoration:`none`,
      minWidth:120,
      fontSize: 14,
      fontWeight:500,
      px:2.5,
      py:1.5,
      '&:hover':{
        background:themeCss ? themeCss.theme_main_color: ``,
        color: theme.palette.background.default,
        textDecoration:`none`,
        opacity:0.9,
      }
    }
};

const iconStyle = {
  color: theme.palette.primary.location,
  fontSize: 18,
  mr:1,
}

  const EVENT_TITLE_MAX_LENGTH = 25;
  return (
    <Link href={`/event/`+props.data.slug+`/`+props.data.id}>
      <Card sx={{...eventCardTwo, width: '100%'}}>           
        <CardContent sx={{px:0, background:'transparent'}}>
          <Typography variant="h3">
            {(props.data.title.length >= EVENT_TITLE_MAX_LENGTH
              ? (props.data.title.toLowerCase()).substring(0, EVENT_TITLE_MAX_LENGTH) +
                "..."
              : props.data.title.toLowerCase()
            ).toLowerCase()}
          </Typography>
          <Typography variant="h5">{props.data.etype}</Typography>
          <Grid container>
            {props.data.only_today != '' && props.data.only_today != '0000-00-00' ? (
              <Grid item xs={12} sm={12} md={7} lg={7} xl={7} sx={{alignSelf:{md:"end", sm:"start", xs:"start"}}}>
                <Typography variant="h6" sx={{display:'flex'}}>
                  <CalendarTodayIcon 
                    sx={{
                      background:`${theme.palette.background.primary}`,
                      borderRadius:0,
                      width:25,
                      height:25,
                      p:0.4
                    }} 
                />{moment(props.data.only_today).format("ddd DD MMM")}</Typography>
              </Grid>
            ):(
              <Grid item xs={12} sm={12} md={7} lg={7} xl={7} sx={{alignSelf:{md:"end", sm:"start", xs:"start"}}}>
                <Typography variant="h6" sx={{display:'flex'}}>
                  <CalendarTodayIcon 
                  sx={{
                    background:`${theme.palette.background.primary}`,
                    borderRadius:0,
                    width:25,
                    height:25,
                    p:0.4
                }} />{moment(props.data.from_date).format("ddd DD MMM")} - {moment(props.data.to_date).format("ddd DD MMM")}</Typography>
              </Grid>
            )}
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{alignSelf:{md:"end", sm:"start", xs:"start"}}}>
                  <Typography variant="h6" sx={{display:'flex', justifyContent:{md:"flex-end", sm:"flex-start", xs:"flex-start"}, mt:{md:0, sm:1, xs:1}}}>
                  <TimerOutlinedIcon 
                    sx={{
                      background:`${theme.palette.background.primary}`,
                      borderRadius:0,
                      width:25,
                      height:25,
                      p:0.4
                    }} 
                />{props.data.start_time}&nbsp;onwards</Typography>
              </Grid>
          </Grid>
        </CardContent>
        <CardMedia
          component="img"
          height="300"
          image={props.data.img_path}
          alt={props.data.title}
        />
        {props.disableButton ? (''):(
          <CardActions sx={{ mt:-4, justifyContent:'center'}}>
            <Typography component="div" sx={ButtonStyleThree} >
              <Link href={'/event/'+props.data.slug+"/"+props.data.id} variant="contained" style={{display:"flex", alignItems:"center"}}>
                  <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                      <Grid container spacing="30">                        
                          { ticketStatus ? (
                            <Grid item>
                              <Typography variant="h4">
                                {reserveStatus && startingPrice > 0 ? (
                                  <><CurrencyRupeeIcon sx={{fontSize:14}}/>{startingPrice}</>
                                ) : ' RSVP'}
                              </Typography>
                            </Grid>
                          ):('')}
                          <Grid item><Typography variant="h6">Buy Now</Typography></Grid>
                      </Grid>
                      <EastIcon style={{marginLeft:'10px', fontSize:14}} />
                  </Stack>
              </Link>
            </Typography>
          </CardActions>
        )}
      </Card>
    </Link>
  );
}