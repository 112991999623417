import React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid } from '@mui/material';
import ButtonTypeFive from '../../components/buttons/TypeFive'
import moment from 'moment'
import TimerIcon from '../../public/images/icons/Timer.png'
import CalendarIcon from '../../public/images/icons/Calendar.png'
import Image from 'next/image'
import Link from 'next/link';
import axios from 'axios'
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


export default function EventFive(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData ? JSON.parse(themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  const [tickets, setTickets] = useState([]);  
  const [startingPrice, setStartingPrice] = useState(0)
  const [ticketStatus, setTicketStatus] = useState(0)
  const [reserveStatus, setReserveStatus] = useState(0)
  useEffect(() => {
    
    const logic = async () => {
      let res2 = await axios.get(
        `/api/events/tickets?slug=${props.data.slug}&id=${props.data.id}`,
        {
          headers: {
            "x-auth-token": process.env.JWT_TOKEN,
          },
        }
      );
      setTickets(res2.data ? res2.data.result : []) 
      setStartingPrice(tickets.length > 0 ? tickets[0].price : 0)
      let reserveStatus = tickets.some((ticket) => ticket.price > 0 && ticket.status == 1);
      setReserveStatus(reserveStatus)
      let ticketStatus = tickets.some((ticket) => ticket.status == 1);  
      setTicketStatus(ticketStatus)
    }
    logic();
  }, [ticketStatus]); 



  const eventCardFive = {
    border:`1px solid ${theme.palette.background.borderColor}`,
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    background:'transparent',
    borderRadius:4,
    mb:2,
    boxShadow:theme.shadows[0],
    transition:`0.5s`,
    '&:hover':{
      boxShadow:theme.shadows[3],
      transition:`0.5s`,
    },
    '& h3':{
      fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:24,
      fontWeight:500,
      color: `${themeCss && themeCss.theme_main_color} !important`,
      mb:2.5,
      textTransform:'capitalize',
    },
    '& h5':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:14,
      fontWeight:700,
      color: theme.palette.primary.contrastText,
    },
    '& h6':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:12,
      fontWeight:400,
      color: theme.palette.primary.contrastText,
      display:'flex',
      alignItems:'center'
    },
    h4:{
      pb:0
    }
}

const iconStyle = {
  color: theme.palette.primary.location,
  fontSize: 18,
  mr:1,
}

  const EVENT_TITLE_MAX_LENGTH = 30;

  return (
    <Link href={`/event/`+props.data.slug+`/`+props.data.id} >
      <Card boxShadow={10} sx={{...eventCardFive}}>
        <CardActionArea>
            <CardMedia
              component="img"
              height="160"
              image={props.data.img_path}
              alt={props.data.title}
              sx={{borderRadius:2, backgroundPosition:'top'}}
              sizes="(max-width: 325px) 100vw"
            />
            <CardContent sx={{borderRadius:2, px:1}}>
              <Typography variant="h3">
              {(props.data.title.length >= EVENT_TITLE_MAX_LENGTH
                  ? (props.data.title.toLowerCase()).substring(0, EVENT_TITLE_MAX_LENGTH) +
                  "..."
                  : props.data.title.toLowerCase()
              ).toLowerCase()}
              </Typography>
              {props.data.only_today != '' && props.data.only_today != '0000-00-00' ? (
                <Grid container>
                  <Grid item xs={12} sm={12} md={7} lg={7} xl={7} alignSelf={"end"} sx={{mb:{md:0,sm:1, xs:1}}}>
                    <Typography variant="h6"><AccessTimeIcon sx={iconStyle} />{moment(props.data.only_today).format("ddd DD MMM")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5} xl={5} alignSelf={"end"}>
                    <Typography variant="h6"><CalendarMonthIcon sx={iconStyle} />{props.data.start_time}&nbsp;onwards</Typography>
                  </Grid>
              </Grid>
              ):(
                <Grid container>
                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7} alignSelf={"end"} sx={{mb:{md:0,sm:1, xs:1}}}>
                      <Typography variant="h6"><AccessTimeIcon sx={iconStyle} />{moment(props.data.from_date).format("ddd DD MMM")} - {moment(props.data.to_date).format("ddd DD MMM")}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5} alignSelf={"end"}>
                      <Typography variant="h6"><CalendarMonthIcon sx={iconStyle} />{props.data.start_time}&nbsp;onwards</Typography>
                    </Grid>
                </Grid>
              )}
              <Grid container spacing={"0"} sx={{mt:4}}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                  { ticketStatus ? (
                      <Typography variant="h5">
                        {reserveStatus && startingPrice > 0 ? (
                          <><CurrencyRupeeIcon sx={{fontSize:14, mr:0.6}}/>{startingPrice}</>
                        ) : ' RSVP'}
                      </Typography>
                  ):('')}                    
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} alignSelf={"end"} textAlign={'right'} sx={{pr:1}}>
                    {props.disableButton ? (''):(
                      <ButtonTypeFive text="Buy Now" link={'/event/'+props.data.slug+'/'+props.data.id} />
                    )}
                  </Grid>
              </Grid>
            </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}