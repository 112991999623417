import React, {useState, useEffect, useContext} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import ButtonTypeTwo from '../../components/buttons/TypeTwo'
import moment from 'moment'
import Link from 'next/link'
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';



export default function EventTwo(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData ? JSON.parse(themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  const eventCardTwo = {
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    background:`${theme.palette.background.alternate} !important`,
    borderRadius:0,
    boxShadow:theme.shadows[0],
    "& .cardContent":{
      px:1,
      mb:2,
      boxShadow:theme.shadows[0],
    },
    mb:1,
    '& h3':{
      fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:24,
      fontWeight:500,
      color: `${themeCss && themeCss.theme_main_color} !important`,
      mb:1,
      textTransform:'capitalize',
    },
    '& h5':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:14,
      fontWeight:400,
      color: theme.palette.primary.contrastText,
      mb:2,
    },
    '& h6':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:12,
      fontWeight:400,
      color: theme.palette.primary.location,
      display:'flex', 
      alignItems:'center'
    },
    h4:{
      pb:0
    }
  }

  const ButtonDateTwo = {
      position: 'relative',
      marginBottom:'-100px',
      marginRight:'15px',
      marginTop:'15px',
      display:'block',
      float:'right',
      fontWeight: 700,
      justifyContent: 'space-evenly',
      background:`${themeCss && themeCss.theme_main_color} !important`,
      p:1,
      minWidth:'55px !important',
      minHeight:'50px !important',
      borderRadius:3,
      textAlign:'center',
      '& h4':{
          fontSize:20,
          fontWeight:700,
          color: `${theme.palette.background.default} !important`,
          mb:0,
          pb:0,
      },
      '& h5':{
          fontSize:12,
          fontWeight:400,
          color: `${theme.palette.primary.contrastText} !important`,
          mb:`0px !important`,
      },
  }

  const iconStyle = {
    color: theme.palette.primary.location,
    fontSize: 18,
    mr:1,
  }

  const EVENT_TITLE_MAX_LENGTH = 20;
  
  return (
    <Link href={'/event/'+props.data.slug+'/'+props.data.id}>
      <Card sx={{...eventCardTwo, borderRadius:5, background:'none'}} elevation={0}>
      {props.data.only_today != '' && props.data.only_today != '0000-00-00' ? (
          <Typography component="div" sx={ButtonDateTwo}>
            <Typography variant="h4">{moment(props.data.only_today).format('DD')}</Typography>
            <Typography variant="h5">{moment(props.data.only_today).format('MMM')}</Typography>
          </Typography>
        ):(
          <Typography component="div" sx={ButtonDateTwo}>
              <Typography variant="h4">{moment(props.data.from_date).format('DD')} - {moment(props.data.to_date).format('DD')}</Typography>
              <Typography variant="h5">{moment(props.data.from_date).format('MMM')} - {moment(props.data.to_date).format('MMM')}</Typography>
          </Typography>
        )}
        <CardMedia
          component="img"
          height="300"
          width="300"
          image={props.data.img_path}
          alt={props.data.title}
          sx={{borderRadius:4}}
        />
        
          
              
        <CardContent className="cardContent">
          <Typography 
            sx={{ 
              p:2, 
              borderRadius:4, 
              background:`${theme.palette.background.alternate}`, 
              border:`1px solid ${theme.palette.primary.borderColor}`, 
              position:'relative', 
              width:'95%',
              marginLeft:'2.5%',
              marginRight:'2.5%',
              marginTop:-7.5,
              boxShadow:'0px 4px 10px 4px #0000000D',              
            }}
            >
            <Typography variant="h3">
              {(props.data.title.length >= EVENT_TITLE_MAX_LENGTH
                ? (props.data.title.toLowerCase()).substring(0, EVENT_TITLE_MAX_LENGTH) +
                  "..."
                : props.data.title.toLowerCase()
              ).toLowerCase()}
            </Typography>
            <Typography variant="h5">{props.data.etype}</Typography>
            <Grid container>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} alignSelf={"end"}>                    
                    <Typography variant="h6" sx={{display:'flex'}}><AccessTimeIcon sx={iconStyle} />{props.data.start_time}&nbsp;onwards</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} alignSelf={"end"} textAlign={'right'}>
                  {props.disableButton ? (''):(
                      <ButtonTypeTwo text="Buy Now" link={'/event/'+props.data.slug+'/'+props.data.id} />
                  )}
                  </Grid>
            </Grid>
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
}