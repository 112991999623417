import React, {useState, useEffect} from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import moment from 'moment'
import Link from 'next/link'
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


export default function EventOne(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);
  

  useEffect(()=>{
    let custom_css = themeData ? JSON.parse(themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  const eventCardSeven = {
    p:1, 
    border:`1px solid ${theme.palette.primary.borderColor}`,
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    background: theme.palette.background.default,
    borderRadius:2,
    mb:2,
    transition: `0.5s`,
    border:`1px solid ${theme.palette.background.borderColor}`,
    boxShadow: theme.shadows[0],
    "&:hover":{
      transition: `0.5s`,
      boxShadow: theme.shadows[3],
      background: theme.palette.background.alternate
    },
    '& h3':{
      fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:{md:30, sm:26, xs:22},
      fontWeight:700,
      color: `${themeCss && themeCss.theme_main_color} !important`,
      textTransform:'capitalize',
    },
    '& h6':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:`12px !important`,
      fontWeight:400,
      color: theme.palette.primary.location,
    },
    h4:{
      pb:0
    }
}

const ButtonDateSeven = {
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    background: `${theme.palette.background.alternate} !important`,
    minHeight: 50,
    minWidth:50,
    borderRadius: 2,
    paddingBottom: `10px !important`,
    '&.MuiCardContent-root':{
      '& :last-child':{
        pb: `10px !important`
      }
    },
    '& h5':{
      fontSize:{md:`22px !important`,sm:`16px !important`,xs:`16px !important`},
      fontWeight:900,
      color: `${themeCss && themeCss.theme_main_color} !important`,
      my:0.4,
      pb: 0
    },
    '& h6':{
      fontSize:{md:`14px !important`,sm:`12px !important`,xs:`12px !important`},
      fontWeight:400,
      color: `${theme.palette.primary.title} !important`,
      p:0 
    },
  }

  const iconStyle = {
    color: theme.palette.primary.location,
    fontSize: 18,
    mr:0.5,
  }
  

  const EVENT_TITLE_MAX_LENGTH = 40;

  return (
    <Link href={'/event/'+props.data.slug+'/'+props.data.id}>
      <Typography component="div" sx={{...eventCardSeven}}>
          <Stack display="flex" flexDirection="row" alignItems="center" sx={{width:'100%'}}>
            {props.data.only_today !='' && props.data.only_today != '0000-00-00' ? (
              <Typography component="div">
                <CardContent sx={{ textAlign:'center', ...ButtonDateSeven }}>
                    <Typography variant="h6">{moment(props.data.only_today).format("ddd")}</Typography>
                    <Typography variant="h5">{moment(props.data.only_today).format("DD")}</Typography>
                    <Typography variant="h6">{moment(props.data.only_today).format("MMM")}</Typography>
                </CardContent>
              </Typography>
            ):(
              <Typography component="div">
                <CardContent sx={{ textAlign:'center', ...ButtonDateSeven }}>
                    <Typography variant="h6">{moment(props.data.from_date).format("ddd")} - {moment(props.data.to_date).format("ddd")}</Typography>
                    <Typography variant="h5">{moment(props.data.from_date).format("DD")} - {moment(props.data.to_date).format("DD")}</Typography>
                    <Typography variant="h6">{moment(props.data.from_date).format("MMM")} - {moment(props.data.to_date).format("MMM")}</Typography>
                </CardContent>
              </Typography>
            )}
              <Typography component="div" sx={{alignItems:'center', ml:2, flexGrow:1}}>
                  <Typography variant="h3">
                      {(props.data.title.length >= EVENT_TITLE_MAX_LENGTH
                          ? (props.data.title.toLowerCase()).substring(0, EVENT_TITLE_MAX_LENGTH) +
                          "..."
                          : props.data.title.toLowerCase()
                      ).toLowerCase()}
                  </Typography>
                  <Typography variant="h6"><CalendarMonthIcon sx={iconStyle} />&nbsp;{props.data.start_time}&nbsp;onwards</Typography>
              </Typography>
              <Typography componen="div">
                <ArrowForwardIosIcon sx={{...iconStyle, fontSize: 30}} />
              </Typography>
          </Stack>
      </Typography>
    </Link>
  );
}