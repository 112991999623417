import React, {useState, useEffect} from 'react';
import EastIcon from '@mui/icons-material/East';
import Link from 'next/link'
import { Typography, Button } from '@mui/material';
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';


function TypeOne(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();

  const [loading, setLoading] = useState(true)
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
      let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
      setThemeCss(custom_css);
  }, [themeData])
  

  const ButtonStyleFive = {
    '& a':{
      fontFamily: themeCss ? themeCss.theme_body_font.replace("+"," ") : '',
      borderRadius:0,
      background:'transparent',
      color:themeCss ? themeCss.theme_main_color: ``,
      textDecoration:`none`,
      fontSize: 16,
      fontWeight:500,
      p:0,
      display:'block !important',
      '&:hover':{
        background:'transparent',
        color:themeCss ? themeCss.theme_main_color: ``,
        textDecoration:`none`,
        opacity:0.9,
      }
    },
    '& button':{
      fontFamily: themeCss ? themeCss.theme_body_font.replace("+"," ") : '',
      borderRadius:0,
      background:'transparent',
      color:themeCss ? themeCss.theme_main_color: ``,
      textDecoration:`none`,
      fontSize: 14,
      fontWeight:500,
      boxShadow:'none',
      p:0,
      display:'block !important',
      '&:hover':{
        background:'transparent',
        color:themeCss ? themeCss.theme_main_color: ``,
        textDecoration:`none`,
        opacity:0.9,
        boxShadow:'none',
      }
    }
};

  return (
    <Typography component="div" sx={ButtonStyleFive} >
      {props.link != '' ? (
        <Link href={props.link} style={{ display:"flex", alignItems:"center", ...props.style}}>
          <>
            {props.text} 
            <EastIcon style={{marginLeft:'5px', fontSize:14}} />
          </>
        </Link>
      ):(
        <Button type="submit" variant="contained" style={{...props.sx, display:"flex", alignItems:"center"}}>
        <>
          {props.text} 
          <EastIcon style={{marginLeft:'5px', fontSize:14}} />
        </>
      </Button>
      )}
    </Typography>    
  )
}

export default TypeOne