import React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid } from '@mui/material';
import ButtonTypeOne from '../../components/buttons/TypeOne'
import moment from 'moment'
import Link from 'next/link'
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


export default function EventOne(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData ? JSON.parse(themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  const eventCardOne = {
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    background:'transparent',
    borderRadius:0,
    mb:2,
    border:`1px solid ${theme.palette.background.borderColor}`,
    boxShadow:theme.shadows[0],
    transition:`0.5s`,
    '& .cardContent':{
      transition:`0.5s`,
      px:1
    },
    "&:hover":{
      boxShadow:theme.shadows[3],
      background: theme.palette.background.alternate,
    },
    '& h3':{
      fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:24,
      fontWeight:500,
      color: `${themeCss && themeCss.theme_main_color} !important`,
      mb:1,
      textTransform:'capitalize',
      transition:`0.5s`,
    },
    '& h5':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:14,
      fontWeight:400,
      color: theme.palette.primary.contrastText,
      mb:2,
    },
    '& h6':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:12,
      fontWeight:400,
      color: theme.palette.primary.location,
      display:'flex',
      alignItems:'center'
    },
    '&:hover':{
      background: `unset`
    },
    h4:{
      pb:0
    }
}

const ButtonDateOne = {
  display:'block',
  position:'absolute',
  marginTop:{md:-9.5,sm:-9, xs:-8.5},
  right:`0 !important`,
  py:1,
  px:1.5,
  background: `${themeCss && themeCss.theme_main_color} !important`,
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  alignItems:'center',
  '& h4':{
    fontFamily:themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
    fontSize:{md:30, sm:24, xs:22},
    fontWeight:700,
    color: theme.palette.background.default,
    mb:`0px !important`,
    pb:`0px !important`,
  },
  '& h5':{
    fontFamily:themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    fontSize:18,
    fontWeight:400,
    color: `${theme.palette.primary.contrastText} !important`,
    mb:`0px !important`,
  },
}

const iconStyle = {
  color: theme.palette.primary.location,
  fontSize: 18,
  mr:1,
}

const EVENT_TITLE_MAX_LENGTH = 20;

  return (
    <Link href={'/event/'+props.data.slug+'/'+props.data.id} >
      <Card sx={eventCardOne} raised={true}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="300"
            width="300"
            image={props.data.img_path}
            alt={props.data.title}
            sx={{borderRadius:0}}
          />
          {props.data.only_today !='' && props.data.only_today !='0000-00-00' ? (
          <Typography component="div" sx={ButtonDateOne}>
              <Typography variant="h4">{moment(props.data.only_today).format("DD")}</Typography>
              <Typography variant="h5">{moment(props.data.only_today).format("MMM")}</Typography>
          </Typography>
          ):(
            <Typography component="div" sx={ButtonDateOne}>
              <Typography variant="h4">{moment(props.data.from_date).format("DD")}-{moment(props.data.to_date).format("DD")}</Typography>
              <Typography variant="h5">{moment(props.data.from_date).format("MMM")}</Typography>
            </Typography>
          )}
          <CardContent className='cardContent'>
          <Typography variant="h3">
            {(props.data.title.length >= EVENT_TITLE_MAX_LENGTH
              ? (props.data.title.toLowerCase()).substring(0, EVENT_TITLE_MAX_LENGTH) +
                "..."
              : props.data.title.toLowerCase()
            ).toLowerCase()}
          </Typography>
                <Grid container>
                  <Grid item xs={7} sm={7} md={7} lg={7} xl={7} alignSelf={"end"} alignItems="center">
                    <Typography variant="h5">{props.data.etype}</Typography>
                    <Typography variant="h6"><CalendarMonthIcon sx={iconStyle} />{props.data.start_time} onwards</Typography>
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5} alignSelf={"end"} textAlign={'right'}>
                  {props.disableButton ? (''):(
                      <ButtonTypeOne text="Buy Now" link={'/event/'+props.data.slug+'/'+props.data.id} 
                      sx={{
                        display:'block',
                        textAlign:'center',
                      }}/>
                  )}
                </Grid>
              </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}